@import './colour.scss';
@import './font.scss';


.text {
  margin: 0px;
}

.page-header-nav {
  .text {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 23px;
    color: $primary-text;
    font-weight: $bold-weight;
    line-height: 39.1px;
  }
}

.page-footer-nav {
  .text {
    color: $contrast-text;
  }
}

.form {
  .title.text, .title .text {
    font-size: 28px;
    text-align: center;
    padding: 10px;
  }
}