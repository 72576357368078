@import './colour.scss';

.audioplay-pagination-bullet-active-primary {
  background-color: $primary;
  opacity: 1;
}

.swiper-button-next, .swiper-button-prev {
  color: $primary;
  background-color: #fffa;
  padding: 5px;
  border-radius: 5px;
  width: 37px;
  height: 54px;
}

.swiper-wrapper {
  align-items: center;
}