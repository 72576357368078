.audioplay-list {
  width: 100%;
  display: flex;
  flex-direction: column;

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .cell {
      height: 100%;
    }
  }

  .header.row {
    background-color: rgb(201, 191, 191);
    font-weight: 600;
    font-size: 24px;
  }


  .row.item.odd{
    background-color: rgb(226, 231, 236);
  }


}