
.grid-input {
  &:focus-visible {
    outline: none;
  }
  border: 2px solid black;
  border-radius: 5px;
  height: 30px;
  background-color: unset;
  color: black;
  text-align: center;
  min-width: 50px;
}

.grid-input-number {
  width: 80px;
}

.grid-input-section {
  color: black;
}

.grid-input-option {
  width: 150px;
}

.presenter-grid-cell {
  width: 50%;
  vertical-align: middle;
}

.presenter-grid-cell-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  text-align: center;
  vertical-align: center;
}
