.icon.standard {
  width: 80px;
  min-width: 80px;
  max-width: 80px;

  height: 80px;
  min-height: 80px;
  max-height: 80px;
  border-radius: 40px;
  overflow: hidden;
}

.icon {
  img {
    width: 100%;
    height: 100%;
  }
}