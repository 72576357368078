@import './colour.scss';
@import './font.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';


@mixin button-audioplay-variant($colour, $background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {
  color: $colour;
  @include gradient-bg($background);
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  @include hover() {
    color: $colour;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
  }

  &:focus,
  &.focus {
    color: $colour;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
    @if $enable-shadows {
      @include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5));
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5);
    }
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: $colour;
    background-color: $background;
    border-color: $border;
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $colour;
    background-color: $active-background;
    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }
    border-color: $active-border;

    &:focus {
      @if $enable-shadows and $btn-active-box-shadow != none {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), .5);
      }
    }
  }
}

@mixin button-audioplay-variant-no-focus($colour, $background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {
  color: $colour;
  @include gradient-bg($background);
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  @include hover() {
    color: $colour;
    border-color: $hover-border;
  }

  &:focus,
  &.focus {
    color: $colour;
    border-color: $hover-border;
    box-shadow: 0 0 0 0 $colour;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: $colour;
    background-color: $background;
    border-color: $border;
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $colour;
    background-color: $active-background;
    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }
    border-color: $active-border;
    &:focus {
      box-shadow: 0 0 0 0 $colour;
    }
  }
}

@mixin button-audioplay-outline-variant($colour, $color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
  color: $colour;
  border-color: $color;

  @include hover() {
    color: $colour;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
  }

  &.disabled,
  &:disabled {
    color: $colour;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $colour;
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      @if $enable-shadows and $btn-active-box-shadow != none {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
      }
    }
  }
}

@mixin button-audioplay-outline-variant-no-focus($colour, $color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
  color: $colour;
  border-color: $color;

  @include hover() {
    color: $colour;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 0 $color;
  }

  &.disabled,
  &:disabled {
    color: $colour;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $colour;
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      box-shadow: 0 0 0 0 $color;
    }
  }
}

.btn-audioplay-primary {
  @include button-audioplay-variant($secondary-text, $secondary, darken($secondary, 15.5%));
}
  
.btn-outline-audioplay-primary {
  @include button-audioplay-outline-variant($secondary-text, $secondary);
}

.btn-audioplay-secondary {
  @include button-audioplay-variant($primary-text, $primary, lighten($primary, 7.5%));
}
  
.btn-outline-audioplay-secondary {
  @include button-audioplay-outline-variant($primary-text, $primary);
}

.btn-audioplay-clear {
  @include button-audioplay-variant-no-focus(#000,transparent,transparent);
}
  
.btn-outline-audioplay-clear {
  @include button-audioplay-outline-variant-no-focus(transparent,transparent);
}

.btn-clear {
  @include button-audioplay-variant-no-focus(#000,transparent,transparent);
}
  
.btn-outline-clear {
  @include button-audioplay-outline-variant-no-focus(transparent,transparent);
}