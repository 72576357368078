@import './colour.scss';

.page-holder {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-holder {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.page-header-nav {
  height: 100px;
  display: flex;
  flex-direction: row;
  background-color: $primary;
  align-items: center;
  justify-content: space-evenly;
  .block {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .block.logo {
    justify-content: center;
  }
  .block.menu {
    justify-content: space-evenly;
  }
  .logo .image {
    height: 100%;
  }
  .tag-line .image {
    height: 30%;
  }
  .tag-line.image {
    height: 30%;
  }
}

.page-footer-nav {
  min-height: 60px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  background-color: $contrast;
  align-items: center;
  justify-content: space-evenly;
}


.audioplay-table {
  td {
    padding: 0;
    min-width: 120px;
  }
}