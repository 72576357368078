@import './colour.scss';
@import './font.scss';

.form-row {
  display: flex;
  flex-direction: row;
  //justify-content: space-between;
  > :nth-child(n) {
    margin-right: 5px;
    margin-left: 5px;
  }
}


.form-row.is-invalid {
  border: 1px solid #dc3545;
  box-sizing: content-box;
  overflow: clip;
  border-radius: .25rem;
}
.section.is-invalid {
  border: 1px solid #dc3545;
  box-sizing: content-box;
  overflow: clip;
  border-radius: .25rem;
}

.form{
  width: 100%;
  .section{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  

  .btn-primary {
    background-color: $secondary;
    border-color: $secondary;
    color: $secondary-text;
    font-weight: $bold-weight;
  }
  
  .validation-control {
    border: 1px solid #ced4da;
    box-sizing: content-box;
    overflow: clip;
    border-radius: .25rem;
  }

  .validation-control.is-valid, .was-validated .validation-control:valid {
    border-color: #28a745;
  }

  .validation-control.is-invalid, .was-validated .validation-control:invalid {
    border-color: #dc3545;
  }


  .control-group {
    display: flex;
    flex-direction: column;
    // flex-grow: 1;
    justify-content: space-evenly;
    align-items: center;
    
    label {
      margin: 0px;
      min-width: 15%;
      //white-space: nowrap;
    }

    label.action {
      color: $primary;
    }

    .action:hover {
      cursor: pointer;
    }

    .control.button {
      width: initial;
      min-width: 120px;
      flex-grow: 0;
    }
  }
  label {
    margin-bottom: 0px;
  }
}