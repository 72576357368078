$primary: rgba(255,0,87,1);
$primary-text: white;

$secondary: #0bd9fe;
$secondary-text: black;

$contrast: #021625;
$contrast-text: white;

$section: white;
$section-alternate: #f2f2f2;
