.presenter-pack {
  margin: 15px 30px;
}

.medium-horizontal-scroll {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.medium-no-wrap {
  flex-wrap: nowrap;
}

.medium-fixed-width {
  min-width: 450px;
}

.medium-font-size {
  font-size: 24px;
}

.small-padding.presenter-pack {
  padding: 30px 45px;
  margin: 0px;
}

@media screen and (max-width: 480px) {
  .small-width {
    display: none;
  }

  .small-margin {
    margin: 0px;
  }

  .small-padding.presenter-pack {
    padding: 15px;
  }
}

@media screen and (max-width: 768px) {
  .medium-width {
    display: none;
  }

  .medium-margin {
    margin: 0px;
  }

  .medium-horizontal-scroll {
    flex-direction: column;
  }

  .medium-no-wrap {
    flex-wrap: wrap;
  }

  .medium-fixed-width {
    min-width: unset;
  }

  .medium-font-size {
    font-size: 14px;
  }
  
}

